import { getStoryblokApi, ISbStoryData } from '@storyblok/react';
import { TemplateDataBlokProps } from '../TemplateData/TemplateData';

export const buildRedirectPageUrl = async (link: Link | undefined) => {
  if (!link) {
    return '';
  }

  if (link.linktype === 'story') {
    if (link.cached_url.startsWith('landing-pages')) {
      const slug = link.cached_url;

      return `${window.location.origin}/${slug.split('/').slice(1).join('/')}/`;
    }

    if (link.cached_url.includes('/landing-pages/')) {
      const [, ...linkSlugSplitted] = link.cached_url
        .split('/')
        .filter(Boolean);
      return `${window.location.origin}/${linkSlugSplitted.slice(1).join('/')}`;
    }

    if (link.cached_url.includes('website/default')) {
      const storyblokApi = getStoryblokApi();
      const [langCountry, ...linkSlugSplitted] = link.cached_url
        .split('/')
        .filter(Boolean);

      const { data }: LinksDataResult = await storyblokApi.get('cdn/links/', {
        starts_with: linkSlugSplitted.join('/'),
        token: process.env.storyblokPublicAccessToken,
        version: 'published',
      });

      const linkData = Object.values(data.links).find(
        (item) => item.slug === link.cached_url.split('/').slice(2).join('/'),
      );

      const alternate = linkData?.alternates.find((item) =>
        item.lang.startsWith(langCountry),
      );

      if (alternate) {
        const targetSlug = [
          alternate.lang,
          ...alternate.translated_slug
            .split('website/default/')
            .filter(Boolean),
        ]
          .join('/')
          .split('/')
          .filter(Boolean)
          .join('/');

        return `${window.location.origin}/${targetSlug}`;
      }
    }
  }

  return link.url || link.cached_url;
};

export const getDataForParamValue = async (
  paramValue: string,
): Promise<Record<string, string>> => {
  const storyblokApi = getStoryblokApi();
  const templateDataSet: Array<ISbStoryData> = await storyblokApi.getAll(
    'cdn/stories',
    {
      filter_query: {
        paramValue: {
          like: paramValue,
        },
      },
      content_type: 'templateData',
      version: 'published',
      // token: process.env.storyblokPublicAccessToken,
    },
  );

  const [storyData] = templateDataSet.filter(
    (dataItem: ISbStoryData) =>
      (dataItem.content as TemplateDataBlokProps).paramValue === paramValue,
  );

  if (storyData) {
    const { content } = storyData;
    const { data } = content as TemplateDataBlokProps;

    return (
      data?.reduce((memo, field) => {
        switch (field.component) {
          case 'templateDataString': {
            const key = (field.key as string) || '';
            const { value } = field;
            if (value) {
              // eslint-disable-next-line no-param-reassign
              memo[key] = value as string;
            }
            break;
          }
          case 'templateDataImage': {
            const key = (field.id as string) || '';
            const { filename: value } = field.image as Asset;
            if (value) {
              // eslint-disable-next-line no-param-reassign
              memo[key] = value;
            }
            break;
          }
        }
        return memo;
      }, {} as Record<string, string>) || {}
    );
  }

  return {};
};
