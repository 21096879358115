import { storyblokEditable, SbBlokData } from '@storyblok/react';
import { ComponentType } from 'react';

import TitleComponent from '@/components/Title/Title';
import { PageContainer } from '@/components';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface TitleBlokProps extends SbBlokData {
  header: string;
  headingLevel?: 'h1' | 'h2';
  subheader: string;
  layout?: 'default' | 'full';
  links?: Array<CtaLinkBlokProps>;
  topic?: string;
}

interface TitleProps {
  alignment?: 'left' | 'center' | 'right' | '';
  blok: TitleBlokProps;
  headingLevel?: ComponentType | keyof JSX.IntrinsicElements;
  backgroundColor?: string;
  hideBackground?: 'yes' | 'no';
  titleTextColor?: string;
}

const Title = ({ blok, ...rest }: TitleProps) => {
  const renderFull = () => {
    const { links, ...restBlok } = blok;

    return (
      <div className="full bg-GSblue/50">
        <PageContainer className="lg:py-20">
          <TitleComponent {...restBlok} {...rest}>
            {links && links.length > 0 && (
              <div className="flex flex-col sm:flex-row sm:gap-4">
                {links.map((linkBlok, index) => (
                  <div
                    key={linkBlok._uid}
                    className="flex sm:gap-4 items-center"
                  >
                    {index > 0 && (
                      <span className="text-GSblue/600 hidden sm:block">|</span>
                    )}
                    <CtaLink blok={linkBlok} />
                  </div>
                ))}
              </div>
            )}
          </TitleComponent>
        </PageContainer>
      </div>
    );
  };

  return blok ? (
    <div {...storyblokEditable(blok)} className="w-full">
      {blok.layout === 'full' ? (
        renderFull()
      ) : (
        <TitleComponent {...blok} {...rest} />
      )}
    </div>
  ) : null;
};

export default Title;
